<template>
  <section style="min-height:100%">
    <div class="banner">
      <div class="banner-text">
        <div class="container">
          <h2 class="banner-title">ASCVTS 2024</h2>
          <h2 class="banner-sub-title">Photo Gallery</h2>
        </div>
      </div>
    </div>
    <section class="main-container">
      <div class="container">
        <div class="left">
          <div class="div">
            <div class="widget">
              <h3 class="widget-title"><span id="main-title-name">Photo Gallery</span></h3>
              <ul class="nav-tabs">
                <li class="active"><a href="">May 23, 2024</a></li>
                <li><a href="">May 24, 2024</a></li>
                <li><a href="">May 25, 2024</a></li>
                <li><a href="">May 26, 2024</a></li>
              </ul>
            </div>
          </div>
        </div>
        <div class="right">
          <h3 class="sub-title">Photo Gallery / <span>2024-5-23</span> </h3>
          <div class="img-box">
            <!-- <div class="img-item" v-for="i in 16" :key="i">
              <div class="img">
                <img :src="`https://www.ascvts2023busan.org/images/photo/${i}.jpg?ver=0620`" alt="">
              </div>
            </div> -->
          </div>
          <p style="fontSize:20px">To be updated.</p>
        </div>
      </div>
    </section>
  </section>
</template>

<script>
export default {
  name:'about'
}
</script>

<style lang="less" scoped>
.banner {
  position: relative;
  min-height: 300px;
  color: #fff;
  background-position: 50% 50%;
  background-size: cover;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.1) ), url('../../assets/img/about/banner.png');
  .banner-text {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    z-index: 1;
    transform: translateY(-50%);
    .container {
      max-width: 1320px;
      margin: 0 auto;
      text-rendering: optimizeLegibility;
      -webkit-font-smoothing: antialiased !important;
      .banner-title {
        color: #e8bd55;
        text-transform: uppercase;
        font-size: 58px;
        font-weight: 900;
      }
      .banner-sub-title{
        color: #fff;
        font-size: 48px;
        font-weight: 900;
      }
      @media (max-width:1320px) {
        padding: 0 15px;
      }
      @media (max-width:768px) {
        .banner-title {
          font-size: 48px;
        }
        .banner-sub-title {
          font-size: 38px;
        }
      }
    }
  }
}
.main-container {
  height: 100%;
  .container {
    max-width: 1320px;
    margin: 0 auto;
    display: flex;
    .left {
      flex: 0 0 25%;
      max-width: 25%;
      padding: 0 15px;
      .div {
        height: 100%;
        border-right: solid 1px #ddd;
        border-left: solid 1px #ddd;
        background-color: #f8f9fa;
        .widget {
          padding: 30px 15px 40px;
          position: sticky;
          top: 70px;
          .widget-title{
            font-size: 27px;
            font-weight: 900;
            position: relative;
            padding-left: 30px;
            margin: 0 -15px;
            background-color: #19317e;
            color: #fff;
            text-transform: uppercase;
          }
          .nav-tabs li {
            color: #444444;
            line-height: normal;
            a {
              position: relative;
              color: #444444;
              padding: 15px 0 15px 15px;
              font-weight: 600;
              border-bottom: 1px solid #ddd;
              display: block;
              transition: all .3s;
              font-size: 18px;
            }
            &:hover a{
              color: #1B3287;
            }
            &.active a {
              color: #1B3287;
              &::before {
                font-family: "iconfont";
                content:'\e6ae';
                position: absolute;
                top: 50%;
                right: 0;
                transform: translateY(-50%);
              }
            }
          }
        }
      }
    }
    .right {
      flex: 0 0 75%;
      position: relative;
      padding: 0 15px 100px;
      .sub-title {
        font-weight: 900;
        font-size: 27px;
        line-height: normal;
        margin: 30px 0px 20px 0px;
        padding-bottom: 10px;
        border-bottom: solid 1px #ddd;
        span {
          color: #1B3287;
        }
      }
      .img-box {
        display: flex;
        flex-wrap: wrap;
        .img-item {
          flex: 0 0 25%;
          padding: 10px;
          .img {
            background-color: #ccc;
            img {
              width: 100%;
            }
          }
          @media (max-width:992px) {
            flex: 0 0 33.3%;
          }
          @media (max-width:576px) {
            flex: 0 0 50%;
          }
        }
      }
    }
    @media (max-width:768px) {
      .left {
        display: none;
      }
      .right {
        flex: 1;
        padding: 0 15px 40px;
      }
    }
  }
}
</style>
